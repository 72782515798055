import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import store from '../store'
// import {getToken} from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT, // api 的 base_url
  timeout: 20000 // 请求超时时间
})
service.interceptors.request.use(config => {
    config.headers.loginType = 1;
    let userInfo = window.localStorage.getItem('userInfo') && JSON.parse(window.localStorage.getItem('userInfo'))
    if (userInfo && userInfo.token) config.headers.token = userInfo.token;
    if (userInfo && userInfo.mobile) config.headers.mobile = userInfo.mobile;
    return config
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error)
})
// request拦截器
/*service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)*/

// response 拦截器
/*service.interceptors.response.use(
  response => {
    /!**
     * code为非20000是抛错 可结合自己业务进行修改
     *!/
    const res = response.data
    // debugger
    if (res.code !== 100) {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)*/

export default service
